<!-- 提交卡密 -->
<template>
    <div class="submitCard">
        <div class="remark">注：({{ cardTypeObj.gc_remark
            ? cardTypeObj.gc_remark
            : "处理时间：9:00-24:00" }})</div>
        <div class="flex">
            <el-input type="textarea" :rows="15" placeholder="一行一卡，请输入" v-model="textarea">
            </el-input>
            <div class="errTips" v-if="errList.length > 0">
                <div class="header">错误提示</div>
                <div>
                    <li v-for="item in errList" :key="item.index">第{{ item.index + 1 }}行{{ item.tips[0] }}</li>
                </div>
            </div>
            <div class="tip flexColumn XCenter">
                <p>请按照如下规则填写：</p>
                <p>1、每次提交只能上传同一种类同一面值的充值卡卡密</p>
                <p>2、卡号卡密之间请用空格隔开</p>
                <p>3、每张卡输入完按“回车键”换行输入下一张</p>

                <p>例如：
                <pre style="white-space:pre-wrap">{{ cardTypeObj.gc_submit_demo }}</pre>
                </p>
            </div>
        </div>
        <div class="trimCardString"> <el-button @click="clippingCardData" type="primary">一键整理</el-button></div>
        <div class="selectTips">您当前已选择：<span class="sizeColor">{{ cardTypeObj.gc_name }}</span>，面值：<span
                class="sizeColor">{{ faceValueType == 0 ? selectMoneyObj.g_cost : customCostFrom.custom_cost
                }}元，</span> 预计到账：<span class="sizeColor">{{ faceValueType == 0 ? selectMoneyObj.g_account_price :
    customCostFrom.account_price }}</span>
        </div>
        <el-divider></el-divider>
        <el-checkbox v-model="checked">我已仔细阅读并理解 <span @click="toniuniuAgreement"
                class="agreementColor">&lt;&lt;牛牛收卡网转让协议&gt;&gt;</span></el-checkbox>
        <div class="submitButton"> <el-button type="danger" style="width:200px ;font-size:20px"
                @click="submit">确认提交</el-button></div>
    </div>
</template>

<script>
export default {
    props: {
        cardTypeObj: {
            type: Object,
            default: () => {
                return {}
            }
        },
        selectMoneyObj: {
            type: Object,
            default: () => {
                return {}
            }
        },
        customCostFrom: {
            type: Object,
            default: () => {
                return {}
            }
        },
        faceValueType: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            textarea: '',
            errList: [],
            checked: false,
        };
    },

    components: {},

    mounted() { },

    methods: {
        async submit() {
            this.clippingCardData()
            await this.validate()

            let data = {
                gc_id: this.cardTypeObj.gc_id,
                g_id: this.selectMoneyObj.g_id,
                gc_submit_type: this.cardTypeObj.gc_submit_type,
                card_data: this.textarea
            }
            if (this.faceValueType == 1) {
                data.custom_cost = this.customCostFrom.custom_cost
            }

            this.$axios
                .post("/api/shop/addSellRecord", data)
                .then((res) => {
                    console.log(res)
                    if (res.code == "000") {
                        let tips = "提交成功";
                        if (res.data.info.length > 0) {
                            tips = "";
                            // tips = JSON.stringify(res.data.info);
                            for (let i = 0; i < res.data.info.length; i++) {
                                tips += `<div style='width:100%;text-align: left'><strong>${i + 1
                                    }：</strong>${res.data.info[i]}</div>`;
                            }

                            this.$alert(tips, '失败订单', {
                                dangerouslyUseHTMLString: true
                            });

                        } else {
                            this.textarea = "";
                            this.$message({
                                type: "success",
                                message: tips,
                            });
                        }
                    } else {
                        if (res.code != "996") {
                            this.$message({
                                type: "error",
                                message: res.msg,
                            });
                        }
                        if (res.code == '186') {
                            this.$emit('showZFBFrom')
                        }
                    }

                }).catch((err) => {
                    console.log(err)
                    this.$message.error("提交失败");
                });

        },
        validate() {
            if (!this.checked) {
                this.$message.error('请先同意转让协议')
                throw '未同意协议'
            }
            // this.textarea = this.textarea.replace(/ +/g, "");
            // console.log(this.textarea)
            this.textarea = this.textarea.replace(/，/g, ",");
            if (/.*[\u4e00-\u9fa5]+.*$/.test(this.textarea)) {
                this.$message.error("卡密提交不可包含中文");
                throw '卡密提交不可包含中文'
            }
            if (!this.textarea) {
                this.$message.error('卡种卡密不可为空')
                throw '卡种卡密不可为空'
            }
            if (this.faceValueType == 1) {
                if (!this.customCostFrom.custom_cost) {
                    this.$message.error('请输入自定义面值')
                    throw '请输入自定义面值'
                }

            } else {
                if (!this.selectMoneyObj.g_id) {
                    this.$message.error('请选择面值')
                    throw '请选择面值'
                }
            }

        },
        clippingCardData() {
            let rulData = JSON.parse(this.cardTypeObj.gc_verify_rule),
                cardNumLength = Number(rulData.card_num.min_length),
                cardDataLength = Number(rulData.card_data.min_length),
                arr = []
            if (!cardNumLength && !cardDataLength) {
                return false
            }

            // 需要卡号
            if (cardNumLength) {
                arr = this.splitStringByLength(this.textarea.replace(/[^a-zA-Z0-9]/g, ""), cardNumLength + cardDataLength, cardNumLength, cardDataLength)
            } else {
                arr = this.splitStringByLength(this.textarea.replace(/[^a-zA-Z0-9]/g, ""), cardDataLength)
            }
            this.textarea = ''
            for (let i = 0; i < arr.length; i++) {
                this.textarea += arr[i] + '\n'
            }
            this.textarea = this.textarea.replace(/\n$/, '')
        },

        splitStringByLength(str, len, cardNumLength, cardDataLength) {
            if (str.length <= len) {
                if (cardNumLength) {
                    // 需要卡号
                    if (/[, ]+/.test(str) || str.length < cardNumLength) {
                        // 包含空格或逗号,或长度不够
                        return [str];
                    } else {
                        return [`${str.substring(0, cardNumLength)},${str.substring(cardNumLength, str.length)}`]
                    }
                } else {
                    return [str];
                }
            }
            else {
                let temp = str.substring(0, len),
                    firstSubLen = len
                if (cardNumLength) {
                    temp = str.substring(0, len + 1);
                    if (!/[, ]+/.test(temp)) {
                        temp = `${str.substring(0, cardNumLength)},${str.substring(cardNumLength, cardNumLength + cardDataLength)}`
                    } else {
                        firstSubLen = len + 1
                    }
                }

                return [temp].concat(this.splitStringByLength(str.substring(firstSubLen), len, cardNumLength, cardDataLength));

            }
        },
        trimCardString() {
            this.errList = []
            if (!this.textarea) {
                this.$message({
                    type: "error",
                    message: "提交内容不可为空",
                });
                this.errorMessage = '提交内容不可为空'
                return false
            }
            let cardList = this.textarea.split('\n'),
                str = '',
                errList = [],
                rulData = JSON.parse(this.cardTypeObj.gc_verify_rule)
            console.log(rulData)
            cardList.forEach((item, index) => {
                let errData = { index, tips: [] },
                    cardNumMinLength = Number(rulData.card_num.min_length),
                    cardNumMaxLength = Number(rulData.card_num.max_length),
                    cardDataMinLength = Number(rulData.card_data.min_length),
                    cardDataMaxLength = Number(rulData.card_data.max_length)

                // 需要卡号
                if (cardNumMinLength) {
                    // 计算连续空格有几处
                    let spacesNumber = item.match(/ +/g)?.length || 0,
                        // 计算连续逗号有几处
                        commaNumber = item.match(/,/g)?.length || 0
                    console.log(spacesNumber, commaNumber)
                    if (commaNumber > 1) {
                        errData.commaNumber = commaNumber
                        errData.tips.push('存在多处逗号')
                    }
                    if (spacesNumber < 1 && commaNumber < 1) {
                        errData.tips.push('未使用逗号或空格分割卡号卡密')
                    } {
                        if (commaNumber == 1) {
                            console.log('asdasd----')
                            // 有逗号分割时，删除所有空格
                            cardList[index] = cardList[index].replace(/\s/g, '')
                        } else {
                            // 无逗号分割时判断有多处连续空格
                            if (spacesNumber > 1) {
                                errData.spacesNumber = spacesNumber
                                errData.tips.push('存在多处空格')
                            }
                        }
                        console.log(cardList[index], '====111')
                        // 去除除_逗号与空格以外的字符
                        cardList[index] = cardList[index].replace(/[^?,-\w\s]/g, '')
                        // 去除首尾空格
                        cardList[index] = cardList[index].replace(/^\s+|\s+$/g, '')
                        console.log(cardList[index], '====')
                        // 根具逗号或空格分割卡号卡密
                        let arr = cardList[index].split(/[,， ]\s*/)
                        let cardNumLength = arr[0]?.length || 0,
                            cardDataLength = arr[1]?.length || 0
                        if ((cardNumLength < cardNumMinLength || cardNumLength > cardNumMaxLength) || cardDataLength < cardDataMinLength || cardDataLength > cardDataMaxLength) {
                            errData.tips.push('卡号或卡密长度不符合要求')
                        }
                    }
                } else {
                    // 不需要卡号
                    // 去除所有特殊字符
                    cardList[index] = item.replace(/[^\w]/g, '')
                    if (cardList[index].length < cardDataMinLength || cardList[index].length > cardDataMaxLength) {
                        errData.tips.push(`卡密长度不符合要求`)
                    }
                }
                // 字符串连续空格只保留一个
                cardList[index] = cardList[index].replace(/\s+/g, ' ')
                str = str + cardList[index] + '\n'
                if (errData.tips.length > 0) {
                    // errList.push(errData)
                }
            });
            // 删除字符串最后一个换行符
            this.textarea = str.replace(/\n$/, '')
            this.errorMessage = ''
            // for (let i = 0; i < errList.length; i++) {
            //     this.errorMessage += `第${errList[i].index + 1}，`
            //     this.errorMessage += '行格式错误。请检查！'
            // }
            this.errList = errList
            // if (errList.length > 0) {
            //   this.$toast({
            //     type: "fail",
            //     message: "提交内容格式错误",
            //   });
            // } else {
            //   return true
            // }
        },
        toniuniuAgreement() {
            this.$router.push({
                path: '/agreement'
            })
        }
    }
}

</script>
<style lang="scss" scoped>
.submitCard {
    margin: 0 0 30px 15px;

    .remark {
        margin-bottom: 10px;
        color: #f40;
        font-size: 15px;
    }

    .trimCardString {
        margin-top: 15px;
    }

    .errTips {
        width: 50%;
        border: 1px solid #DCDFE6;
        padding: 10px;
        height: 305px;
        overflow: auto;

        .header {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            margin: 10px 0 10px 0;
            color: #767676;
        }

        li {
            list-style-type: auto;
            color: #f83130;
            font-size: 15px;
            margin-bottom: 5px;
        }
    }

    .tip {
        width: 50%;
        border: 1px solid #DCDFE6;
        background-color: #fff;
        border-radius: 4px;

        p {
            color: #f40;
            margin: 0 5px 5px 30px;
            font-size: 15px;
        }

        pre {
            color: #f40;
            margin: 0 0 5px 35px;
            font-size: 15px;
        }
    }

    .selectTips {
        margin: 15px 0 0 5px;
        font-size: 20px;
    }

    .agreementColor {
        color: #f83130;
    }

    .submitButton {
        margin: 20px 0 0 450px;
    }

    .sizeColor {
        color: #f40;
    }
}
</style>