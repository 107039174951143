<!-- 卡券回收常见问题 -->
<template>
    <div></div>
</template>

<script>
export default {
    data() {
        return {
        };
    },

    components: {},

    mounted() { },

    methods: {}
}

</script>
<style scoped></style>