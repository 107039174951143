<!-- 支付宝 -->
<template>
    <div>
        <el-dialog title="设置收款账号" :visible.sync="show" width="30%" :before-close="befaultColse">
            <el-form :model="form" :rules="rules" ref="form" label-width="150px" class="demo-ruleForm">
                <el-form-item label="支付宝账号" prop="u_ali_account">
                    <el-input v-model="form.u_ali_account"></el-input>
                </el-form-item>
                <el-form-item label="支付宝真实姓名" prop="u_ali_realname">
                    <el-input v-model="form.u_ali_realname"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="befaultColse">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        userInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {

            form: {},
            rules: {
                u_ali_account: [
                    { validator: this.validateUAliAccount, trigger: 'blur' }
                ],
                u_ali_realname: [
                    { validator: this.validateUAliRealname, trigger: 'blur' }
                ],
            }
        };
    },

    components: {},
    watch: {
        userInfo: {
            handler: function (val) {
                this.form = val;
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() { },

    methods: {
        async save() {
            let res = await this.$axios
                .post("/api/user/updateInfo", this.form)
            if (res.code == "000") {
                this.$message({
                    type: "success",
                    message: "修改成功",
                });
                this.$emit('closeZFBFrom')
            } else {
                this.$message.error(res.msg)
            }

        },
        befaultColse() {
            this.$emit('closeZFBFrom')
        },
        validateUAliAccount(rule, value, callback) {
            if (!value) {
                callback('支付宝账号')
            }
            this.form.u_ali_account = value.replace(/\s*/g, "");
            // if (
            //     ! /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/.test(value)
            // ) {
            //     callback('支付宝账号格式错误')
            // }
            callback();
        },
        validateUAliRealname(rule, value, callback) {
            if (!value) {
                callback('支付宝姓名')
            }
            this.form.u_ali_realname = value.replace(/\s*/g, "");
            callback();
        },
    }
}

</script>
<style scoped></style>