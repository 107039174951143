<!-- 未登录提醒 -->
<template>
    <div class="notLoginTips flex YCenter" v-if="!$store.state.isLogin"> <i class="el-icon-warning"></i> 您当前尚未登录,登录后才可进行销卡
        <button type="text" @click="jumpMemberCenter">立即登录</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },

    components: {},

    mounted() { },

    methods: {
        jumpMemberCenter() {
            this.$router.push({
                path: '/memberCenter'
            })
        }
    }
}

</script>
<style lang="scss" scoped>
.notLoginTips {
    position: fixed;
    top: 0;
    left: 40%;
    background: #ffe2d7;
    padding: 0 10px 0 10px;
    color: #f70808;

    border-radius: 3px;

    i {
        color: #ffc40a;
        font-size: 20px;
        margin-right: 5px;
    }

    button {
        color: #409eff;
        background: none;
        border: none;
        border-bottom: 1px solid #fff;
        margin-left: 5px;
    }
}
</style>