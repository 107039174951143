<!-- 卡券回收 -->
<template>
  <div class="cardRecycle">

    <div>
      <p class="flex YCenter"><i class="verticalLine"></i> <span class="titleText">选择卡类</span></p>
      <div class=" m-t25 m-b50 flex">
        <p :class="[reqCardTypeParams.gc_type == item.value ? 'selectStyle' : '', 'cardSpeciesItem', 'XYCenter']"
          v-for="item in cardSpeciesList" :key="item.value" @click="selectSpecies(item)">{{ item.text }}</p>
      </div>
    </div>
    <div>
      <p class="flex YCenter"><i class="verticalLine"></i> <span class="titleText">选择卡种</span></p>
      <div class=" m-t25 m-b50 flexWrap">
        <p v-for="item in cardTypeList" :key="item.gc_id" @click="selectCardType(item)"
          :class="[reqMoneyParams.gc_id == item.gc_id ? 'selectStyle' : '', 'ListItem', 'flexColumn', 'YCenter']">
          <img class="img" :src="item.gc_cover" alt="" srcset="">
          <span>{{ item.gc_name }}</span>
          <i v-if="reqMoneyParams.gc_id == item.gc_id" class="selectIcon el-icon-check"></i>
        </p>
      </div>
    </div>
    <div>
      <p class="flex YCenter"><i class="verticalLine"></i> <span class="titleText">选择面值</span></p>
      <div class=" m-t25 m-b50 flexWrap">
        <p :class="[selectMoneyObj.g_id == item.g_id ? 'selectStyle' : '', 'ListItem', 'flexColumn', 'YCenter']"
          @click="selectMoney(item)" v-for="item in moneyList" :key="item.g_id">
          <span class="moneyText">{{ item.g_cost }}元</span>
          <span class="moneyText" style="color:#f83130">{{ item.g_discount }}折</span>
          <span class="moneyText" style="color:#f83130">到账{{ item.g_account_price }}元</span>
          <i v-if="selectMoneyObj.g_id == item.g_id" class="selectIcon el-icon-check"></i>
        </p>
        <p v-if="selectCardObj.show_custom_cost === 1"
          :class="[faceValueType == 1 ? 'selectStyle' : '', 'ListItem', 'flexColumn', 'YCenter']" @click="diyCost">
          <span class="moneyText">自定义面值</span>
          <span class="moneyText" v-if="form.custom_cost">{{ form.custom_cost }}元</span>
          <span class="moneyText" style="color:#f83130">{{ form.custom_discount == '' ? '点击这里' : form.custom_discount +
            '折'
          }}</span>
          <span class="moneyText" style="color:#f83130">{{ form.account_price == '' ? '自定义面值' : '到账' + form.account_price
          }}元</span>
          <i v-if="faceValueType == 1" class="selectIcon el-icon-check"></i>
        </p>
      </div>
    </div>
    <div>
      <p class="flex YCenter"><i class="verticalLine"></i> <span class="titleText">提交卡密</span></p>
      <SubmitCard class=" m-t25" :cardTypeObj="selectCardObj" :selectMoneyObj="selectMoneyObj" :customCostFrom="form"
        :faceValueType="faceValueType" @showZFBFrom="showZFBFrom">
      </SubmitCard>
    </div>
    <commonProblem></commonProblem>
    <el-dialog title="自定义面值" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="面值" prop="custom_cost">
          <el-input v-model="form.custom_cost" @input="changeGcost"></el-input>
        </el-form-item>
        <el-form-item label="预计到账金额">
          <span>{{ form.account_price }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setCustomCost">确 定</el-button>
      </span>
    </el-dialog>
    <notLoginTips></notLoginTips>
    <setZFB :show="show" :userInfo="userInfo" @closeZFBFrom="closeZFBFrom"></setZFB>
  </div>
</template>

<script>
import debounceAndThrottle from "@/tools/debounceAndThrottle";
import SubmitCard from './component/submitCard.vue'
import commonProblem from './component/commonProblem.vue'
import setZFB from '../memberCenter/component/setZFB.vue';
import notLoginTips from '../../components/notLoginTips'
export default {
  data() {
    return {
      dialogVisible: false,
      faceValueType: 0,
      cardSpeciesList: [
        {
          value: 3,
          text: "电商礼品卡",
        },
        {
          value: 4,
          text: "旅游卡",
        },
        {
          value: 1,
          text: "油卡",
        },
        {
          value: 2,
          text: "话费卡",
        },

        {
          value: 5,
          text: "商超百货卡",
        },
        {
          value: 6,
          text: "餐饮服务",
        },
      ],
      reqCardTypeParams: {
        gc_type: 3,
        page: 1,
        limit: 100,
      },
      cardTypeList: [],
      reqMoneyParams: {
        gc_id: 18,
        page: 1,
        limit: 100,
      },
      moneyList: [],
      selectCardObj: {},
      selectMoneyObj: {},
      form: {
        custom_cost: '',
        custom_discount: '',
        account_price: ''
      },
      show: false,
      userInfo: {},
      rules: {
        custom_cost: [{ required: true, message: '请输入面值', trigger: 'blur' }]
      }
    };
  },

  components: {
    SubmitCard,
    notLoginTips,
    commonProblem,
    setZFB
  },

  mounted() {
    this.getCardTypeList()
    if (this.$store.state.isLogin) {
      this.getUserinfo()
    }

  },

  methods: {
    showZFBFrom() {
      console.log('showZFBFrom')
      this.show = true
    },
    closeZFBFrom() {
      this.show = false
    },
    selectSpecies(item) {
      this.reqCardTypeParams.gc_type = item.value

      this.getCardTypeList()
    },
    selectCardType(item) {
      this.selectCardObj = item
      this.reqMoneyParams.gc_id = item.gc_id
      this.getMoneyList()
    },
    selectMoney(item) {
      this.selectMoneyObj = item
      this.faceValueType = 0;
    },
    diyCost() {
      this.dialogVisible = true
      this.faceValueType = 1;
      this.selectMoneyObj = {};
    },
    async setCustomCost() {
      let resut = await this.$refs['form'].validate()
      if (!resut) { return false }
      this.dialogVisible = false
    },
    getCardSpeciesList() {

    },
    changeGcost(value) {
      if (value != "") {
        this.faceValueType = 1;
        this.$emit("fromSelectMoney", { g_id: 0 });
        debounceAndThrottle.debounce(() => {
          this.$axios
            .post("/api/shop/getCustomCostDiscount", {
              gc_id: this.selectCardObj.gc_id,
              g_cost: value,
            })
            .then((res) => {
              this.form.account_price = res.data.account_price;
              this.form.custom_discount = res.data.custom_discount;
              console
            });
        }, 500);
      }
    },
    getCardTypeList() {
      this.moneyList = []
      this.$axios
        .get("/api/shop/getGoodsClassList", {
          params: this.reqCardTypeParams,
        })
        .then((res) => {
          this.cardTypeList = res.data
          if (this.$route.params.gc_id) {
            this.reqMoneyParams.gc_id = this.$route.params.gc_id
            this.selectCardObj = this.$route.params
          } else {
            this.reqMoneyParams.gc_id = res.data[0].gc_id
            this.selectCardObj = res.data[0]
          }

          this.getMoneyList()
        })
    },
    getMoneyList() {
      this.$axios
        .get("/api/shop/getGoodsList", {
          params: this.reqMoneyParams, partner_id: window.localStorage.getItem("partner_id")
            ? window.localStorage.getItem("partner_id")
            : 0,
        }).then(res => {
          this.moneyList = res.data
        })
    },
    getUserinfo() {
      this.$axios
        .get("/api/user/getInfo")
        .then((res) => {
          this.userInfo = res.data;
          if (!this.userInfo.u_ali_account) {
            this.show = true
          }
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.cardRecycle {
  padding: 50px 150px;

  .verticalLine {
    display: inline-block;
    width: 5px;
    height: 25px;
    background-color: $themeColor;
  }

  .titleText {
    margin-left: 30px;
    font-size: 20px;
    font-weight: 600;
  }


  .cardSpeciesItem {
    width: 150px;
    height: 50px;
    border: 1px solid $themeColor;
    border-radius: 8px;
    font-size: 17px;
    margin: 15px;
    background-color: #fff;
  }

  .ListItem {
    position: relative;
    width: 168px;
    border: 2px solid #eaeaea;
    border-radius: 8px;
    font-size: 17px;
    margin: 15px;
    padding: 0 0 15px 0;
    background: #fff;
    box-shadow: 0 2px 12px 0 #00000015;

    .img {
      margin: 3px 3px 10px 3px;
      width: 97%;
      height: 120px;
    }

    .selectIcon {
      position: absolute;
      bottom: 0;
      right: 0;
      color: #fff;
      background: $themeColor ;
      font-size: 30px;
      border-top-left-radius: 18px;
    }
  }

  .moneyText {
    margin-top: 5px;
  }


  .m-t25 {
    margin-top: 25px;
  }

  .m-b50 {
    margin-bottom: 50px;
  }

  .selectStyle {
    border: 2px solid $themeColor;
    // background-color: $themeColor;
    // color: #fff;
  }
}
</style>